import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfig } from '../app-config';
import { ApiErrorResponse, ApiResponse } from '../shared';
import { BaseApiService } from '../shared/http-service/base-api.service';
import { IVerticalTimelineViewModel } from '../shared/vertical-timeline/vertical-timeline-models';
import { ActivityCompetencyNameViewModel, IActivitiesStatsViewModel, IActivityCompetencyCertificatesViewModel, IActivityCompetencyRatingsViewModel, IActivityEventRelatedCompetencyViewModel, IActivityEvidenceSummaryViewModel, IActivityEvidenceViewModel, IActivityTimelineEntryViewModel, IActivityViewModel, IAddActivityEvidenceCommand, IAssignActivityUserCertificateCommand, IAssignUserActivityCompetencyCommand, IAssignUserActivityObjectiveCommand, ICompleteActivityCommand, IDeleteUserActivityEvidenceCommand, IGetActivitiesBySeriesIdQuery, IGetActivitiesStatsQuery, IGetActivityCompetencyCertificatesQuery, IGetActivityCompetencyListQuery, IGetActivityEventRelatedCompetencyQuery, IGetActivityEventsQuery, IGetActivityEvidenceListQuery, IGetActivityQuery, IGetActivityRatingsQuery, IGetUserActivityEvidenceQuery, IGetUserHistoryQuery, IReopenActivityCommand, IRequestActivityExtensionCommand, ISearchUserActivitiesQuery, ISuppressActivityCommand, IUnassignUserActivityCompetencyQuery, IUnassignUserActivityObjectiveQuery, IUpdateActivityEndDateCommand, IUpdateActivityEvidenceCommand, IUpdateActivityEvidenceMediaCommand, IUpdateActivityOccurredOnDateCommand, IUpdateUserActivityObjectiveRatingCommand, IUserActivitySeriesSummaryViewModel, IUserActivitySummaryViewModel, IValidateActivityExtensionRequestCommand } from './activities.models';

@Injectable({
	providedIn: 'root'
})
export class ActivityApiService extends BaseApiService {

	constructor (http: HttpClient, appConfig: AppConfig) {
		super(http, appConfig);
	}

	searchUserActivities (query: ISearchUserActivitiesQuery): Observable<ApiResponse<IUserActivitySummaryViewModel>> {
		const url = this.createUrlWithSearchParams(`${ this.baseUrl }`, query);
		return this.get<ApiResponse<IUserActivitySummaryViewModel>>(url.href);
	}

	getActivity (query: IGetActivityQuery): Observable<ApiResponse<IActivityViewModel>> {
		const url = new URL(`${ this.baseUrl }/user`);
		url.searchParams.append('id', query.id);
		return this.get<ApiResponse<IActivityViewModel>>(url.href);
	}

	getActivityStats (query: IGetActivitiesStatsQuery): Observable<ApiResponse<IActivitiesStatsViewModel>> {
		const url = this.createUrlWithSearchParams(`${ this.baseUrl }/statistics`, query);
		return this.get<ApiResponse<IActivitiesStatsViewModel>>(url.href);
	}

	getActivityEvents (query: IGetActivityEventsQuery): Observable<ApiResponse<IActivityTimelineEntryViewModel[]>> {
		const url = new URL(`${ this.baseUrl }/activity-events?id=${ query.id }`);
		return this.get<ApiResponse<IActivityTimelineEntryViewModel[]>>(url.href);
	}

	getActivityUserRatings (query: IGetActivityRatingsQuery): Observable<ApiResponse<IActivityCompetencyRatingsViewModel[]>> {
		const url = new URL(`${ this.baseUrl }/ratings/user?id=${ query.id }`);
		return this.get<ApiResponse<IActivityCompetencyRatingsViewModel[]>>(url.href);
	}

	getActivityManagerRatings (query: IGetActivityRatingsQuery): Observable<ApiResponse<IActivityCompetencyRatingsViewModel[]>> {
		const url = new URL(`${ this.baseUrl }/ratings/manager?activityId=${ query.id }`);
		return this.get<ApiResponse<IActivityCompetencyRatingsViewModel[]>>(url.href);
	}

	updateObjectiveRating (command: IUpdateUserActivityObjectiveRatingCommand): Observable<ApiResponse<string> | ApiErrorResponse> {
		return this.put<ApiResponse<string> | ApiErrorResponse>(`${ this.baseUrl }/ratings/objective`, command);
	}

	addCompetencyRating (command: IUpdateUserActivityObjectiveRatingCommand): Observable<ApiResponse<string> | ApiErrorResponse> {
		return this.post<ApiResponse<string> | ApiErrorResponse>(`${ this.baseUrl }/ratings/competency`, command);
	}

	completeActivity (command: ICompleteActivityCommand): Observable<ApiResponse<boolean>> {
		return this.post<ApiResponse<boolean>>(`${ this.baseUrl }/complete`, command);
	}

	requestExtensionApproval (command: IRequestActivityExtensionCommand): Observable<ApiResponse<void> | ApiErrorResponse> {
		return this.post<ApiResponse<void> | ApiErrorResponse>(`${ this.baseUrl }/extension/request`, command);
	}

	validateExtensionRequest (command: IValidateActivityExtensionRequestCommand): Observable<ApiResponse<void> | ApiErrorResponse> {
		return this.post<ApiResponse<void> | ApiErrorResponse>(`${ this.baseUrl }/extension/validate`, command);
	}

	reopenActivity (command: IReopenActivityCommand): Observable<ApiResponse<void> | ApiErrorResponse> {
		return this.post<ApiResponse<void> | ApiErrorResponse>(`${ this.baseUrl }/reopen`, command);
	}

	updateActivityOccurredOnDate (command: IUpdateActivityOccurredOnDateCommand): Observable<ApiResponse<void> | ApiErrorResponse> {
		return this.put<ApiResponse<void> | ApiErrorResponse>(`${ this.baseUrl }/occuredon-date`, command);
	}

	updateActivityEndDate (command: IUpdateActivityEndDateCommand): Observable<ApiResponse<void> | ApiErrorResponse> {
		return this.put<ApiResponse<void> | ApiErrorResponse>(`${ this.baseUrl }/end-date`, command);
	}

	updateCompetencyRating (command: IUpdateUserActivityObjectiveRatingCommand): Observable<ApiResponse<string> | ApiErrorResponse> {
		return this.put<ApiResponse<string> | ApiErrorResponse>(`${ this.baseUrl }/ratings/competency`, command);
	}

	getActivityEvidenceList (query: IGetActivityEvidenceListQuery): Observable<ApiResponse<IActivityEvidenceSummaryViewModel>> {
		const url = this.createUrlWithSearchParams(`${ this.baseUrl }/evidence-list`, query);
		return this.get<ApiResponse<IActivityEvidenceSummaryViewModel>>(url.href);
	}

	getActivityEvidence (query: IGetUserActivityEvidenceQuery): Observable<ApiResponse<IActivityEvidenceViewModel>> {
		const url = this.createUrlWithSearchParams(`${ this.baseUrl }/evidence`, query);
		return this.get<ApiResponse<IActivityEvidenceViewModel>>(url.href);
	}

	addActivityEvidence (command: IAddActivityEvidenceCommand): Observable<ApiResponse<string> | ApiErrorResponse> {
		return this.post<ApiResponse<string> | ApiErrorResponse>(`${ this.baseUrl }/evidence`, command);
	}

	updateActivityEvidence (command: IUpdateActivityEvidenceCommand): Observable<ApiResponse<string> | ApiErrorResponse> {
		return this.put<ApiResponse<string> | ApiErrorResponse>(`${ this.baseUrl }/evidence`, command);
	}

	deleteActivityEvidence (command: IDeleteUserActivityEvidenceCommand): Observable<ApiResponse<string> | ApiErrorResponse> {
		const url = this.createUrlWithSearchParams(`${ this.baseUrl }/evidence`, command);
		return this.delete<ApiResponse<string> | ApiErrorResponse>(url.href);
	}

	updateActivityEvidenceMedia (command: IUpdateActivityEvidenceMediaCommand): Observable<ApiResponse<string> | ApiErrorResponse> {
		return this.put<ApiResponse<string> | ApiErrorResponse>(`${ this.baseUrl }/evidence/media`, command);
	}

	getActivityCompetencyList (query: IGetActivityCompetencyListQuery): Observable<ApiResponse<ActivityCompetencyNameViewModel[]>> {
		const url = new URL(`${ this.baseUrl }/activity-competency-list?userActivityId=${ query.userActivityId }`);
		return this.get<ApiResponse<ActivityCompetencyNameViewModel[]>>(url.href);
	}

	getActivityCompetencyCertificates (query: IGetActivityCompetencyCertificatesQuery): Observable<ApiResponse<IActivityCompetencyCertificatesViewModel>> {
		const url = this.createUrlWithSearchParams(`${ this.baseUrl }/competency-certificates`, query);
		return this.get<ApiResponse<IActivityCompetencyCertificatesViewModel>>(url.href);
	}

	assignCertificate (command: IAssignActivityUserCertificateCommand): Observable<ApiResponse<void> | ApiErrorResponse> {
		return this.post<ApiResponse<void> | ApiErrorResponse>(`${ this.baseUrl }/certificate-assign`, command);
	}

	assignCompetency (command: IAssignUserActivityCompetencyCommand): Observable<ApiResponse<string> | ApiErrorResponse> {
		return this.post<ApiResponse<string> | ApiErrorResponse>(`${ this.baseUrl }/competencies/assign`, command);
	}

	assignObjective (command: IAssignUserActivityObjectiveCommand): Observable<ApiResponse<string> | ApiErrorResponse> {
		return this.post<ApiResponse<string> | ApiErrorResponse>(`${ this.baseUrl }/objectives/assign`, command);
	}

	unAssignCompetency (query: IUnassignUserActivityCompetencyQuery): Observable<ApiResponse<string[]> | ApiErrorResponse> {
		return this.put<ApiResponse<string[]> | ApiErrorResponse>(`${ this.baseUrl }/competencies/unassignActivityCompetency`, query);
	}

	unAssignObjective (query: IUnassignUserActivityObjectiveQuery): Observable<ApiResponse<void> | ApiErrorResponse> {
		return this.delete<ApiResponse<void> | ApiErrorResponse>(`${ this.baseUrl }/objectives/${ query.userActivityId }/${ query.activityCompetencyLevelId }/${ query.objectiveId }`);
	}

	getActivityEventRelatedCompetency (query: IGetActivityEventRelatedCompetencyQuery): Observable<ApiResponse<IActivityEventRelatedCompetencyViewModel>> {
		let url = new URL(`${ this.baseUrl }/related-competency`);
		if (query?.competencyLevelId) {
			url.searchParams.append('competencyLevelId', query.competencyLevelId);
		}
		return this.get<ApiResponse<IActivityEventRelatedCompetencyViewModel>>(url.href);
	}

	getUserHistory (query: IGetUserHistoryQuery): Observable<ApiResponse<IVerticalTimelineViewModel[]>> {
		let url = new URL(`${ this.baseUrl }/user-history`);
		url.searchParams.append('userId', query.userId);
		return this.get<ApiResponse<IVerticalTimelineViewModel[]>>(url.href);
	}

	suppressActivity (command: ISuppressActivityCommand): Observable<ApiResponse<void>> {
		return this.post<ApiResponse<void>>(`${ this.baseUrl }/suppress`, command);
	}

	getActivitiesBySeriesId (query: IGetActivitiesBySeriesIdQuery): Observable<ApiResponse<IUserActivitySeriesSummaryViewModel>> {
		const url = this.createUrlWithSearchParams(`${ this.baseUrl }/activities-by-seriesid`, query);
		return this.get<ApiResponse<IUserActivitySeriesSummaryViewModel>>(url.href);
	}

	get baseUrl (): string {
		return `${ this.appConfig.appSettings.apiEndpoints.activityService.url }/${ this.appConfig.appSettings.apiEndpoints.activityService.version }/useractivity`;
	}
}